import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
        path: '/',
        name: 'home',
        component: () => import('./views/Form.vue')
    },
    {
        path: '/medyk-oferta',
        name: 'medyk-oferta',
        component: () => import('./views/medyk-oferta.vue')
    },
    {
      path: '/flota-pr',
      name: 'flota-pr',
      component: () => import('./views/flota-pr.vue')
    },
    {
      path: '/flota-ab',
      name: 'flota-ab',
      component: () => import('./views/flota-ab.vue')
    },
    {
      path: '/empik',
      name: 'empik',
      component: () => import('./views/empik.vue')
    },
    {
      path: '/medicover',
      name: 'medicover',
      component: () => import('./views/medicover.vue')
    },
    {
      path: '/pwc',
      name: 'pwc',
      component: () => import('./views/pwc.vue')
    },
    {
      path: '/ey',
      name: 'ey',
      component: () => import('./views/ey.vue')
    },
    {
      path: '/kpmg',
      name: 'kpmg',
      component: () => import('./views/kpmg.vue')
    },
    {
      path: '/luxmed',
      name: 'luxmed',
      component: () => import('./views/luxmed.vue')
    },
    {
      path: '/enelmed',
      name: 'enelmed',
      component: () => import('./views/enelmed.vue')
    },
    {
      path: '/deloitte',
      name: 'deloitte',
      component: () => import('./views/deloitte.vue')
    },
    {
      path: '/wyprzedaz2024',
      name: 'wyprzedaz2024',
      component: () => import('./views/wyprzedaz2024.vue')
    },
    {
      path: '/pc-wyprzedaz2024',
      name: 'pc-wyprzedaz2024',
      component: () => import('./views/pc-wyprzedaz2024.vue')
    },
    {
      path: '/van-wyprzedaz2024',
      name: 'van-wyprzedaz2024',
      component: () => import('./views/van-wyprzedaz2024.vue')
    },
    {
      path: '/orlen',
      name: 'orlen',
      component: () => import('./views/orlen.vue')
    },
    {
      path: '/mix',
      name: 'mix',
      component: () => import('./views/mix.vue')
    },
    {
      path: '/wgp',
      name: 'wgp',
      component: () => import('./views/wgp.vue')
    },
    {
      path: '/mckinsey',
      name: 'mckinsey',
      component: () => import('./views/mckinsey.vue')
    },
    {
      path: '/bcg',
      name: 'bcg',
      component: () => import('./views/bcg.vue')
    },
    {
      path: '/otomoto',
      name: 'otomoto',
      component: () => import('./views/otomoto.vue')
    },
    {
      path: '/autoDNA',
      name: 'autoDNA',
      component: () => import('./views/autodna.vue')
    },
    {
      path: '/testForm14112024',
      name: 'testForm14112024',
      component: () => import('./views/testForm14112024.vue')
    },
    {
        path: '*',
        name: '404',
        component: () => import('./views/404.vue')
    }
  ]
})
